import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1, fa2, fa3, fa4 } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//styles
import "./Learn.css";

export default function Learn({ showLearn }) {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/tips");
  };
  return (
    <div className="learn">
      <h3>What You Should Know</h3>
      <div className="learn-child">
        <FontAwesomeIcon icon={fa1} className="learn-icon" size="3x" />
        <p className="learn-header">Our Specialty</p>
        <p className="learn-paragraph learn-justify">
          We sell groceries by the case at discounted prices. Our groceries come
          from vendors specializing in manufacturer closeouts, overruns, and
          distressed, past-date or close-to-date items. This allows us to
          provide affordable, wholesome food to our customers, reducing their
          food budgets without sacrificing a nutritious diet.{" "}
        </p>
      </div>
      <div className="learn-grid">
        <div className="learn-child">
          <FontAwesomeIcon icon={fa2} className="learn-icon" size="3x" />
          <p className="learn-header">Changing Inventory</p>
          <p className="learn-paragraph learn-justify">
            {" "}
            You can browse our products anytime, but orders can only be placed
            during designated order windows for your area. Our inventory changes
            frequently, so some items may become unavailable by your order week.
            We're also constantly adding new products, so you'll have fresh
            options when it's time to place your order!{" "}
          </p>
        </div>
        <div className="learn-child">
          <FontAwesomeIcon icon={fa3} className="learn-icon" size="3x" />
          <p className="learn-header">Order Online, Local Pickup</p>
          <p className="learn-paragraph">
            We're a small-town, family-owned business. You place your orders
            online and pick them up in Parsons or one of our community drop
            locations.
          </p>
        </div>
        <div className="learn-child">
          <FontAwesomeIcon icon={fa4} className="learn-icon" size="3x" />
          <p className="learn-header">Monthly Pickup Date</p>
          <p className="learn-paragraph">
            We place an order every month, and our customers have a one week
            order window to place their order. If you miss an order window, no
            worries! There's always next month!
          </p>
        </div>
      </div>
      <button className="button learn-button" onClick={handleNext}>
        {!showLearn ? "Understanding our Product Cards" : "Next"}
      </button>
    </div>
  );
}
