import logo from "../images/Green@small-bold.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";

const Signup = ({ setShowLearn }) => {
  const [userObject, setUserObject] = useState({ location: "Parsons" });
  const [phonePermission, setPhonePermissions] = useState(true);
  const [emailPermission, setEmailPermissions] = useState(true);

  const { error, signup } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();

    userObject.permissions = { phone: phonePermission, email: emailPermission };
    userObject.accountType = "Individual";

    signup(userObject);
    setShowLearn(true);
  };

  return (
    <div className="login grid">
      <img src={logo} alt="" className="logo" />
      <h3>Create your account</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={(e) =>
            setUserObject({ ...userObject, firstName: e.target.value })
          }
          placeholder="First Name"
          autoComplete="on"
          required
        />
        <input
          type="text"
          onChange={(e) =>
            setUserObject({ ...userObject, lastName: e.target.value })
          }
          placeholder="Last Name"
          autoComplete="on"
          required
        />
        <input
          type="email"
          onChange={(e) =>
            setUserObject({ ...userObject, email: e.target.value })
          }
          placeholder="Email"
          autoComplete="on"
          required
        />
        <input
          type="tel"
          onChange={(e) =>
            setUserObject({ ...userObject, phone: e.target.value })
          }
          placeholder="Phone Number"
          autoComplete="on"
          required
        />
        <input
          type="text"
          onChange={(e) =>
            setUserObject({ ...userObject, zipCode: e.target.value })
          }
          placeholder="Zip Code"
          autoComplete="on"
          required
        />
        <input
          type="password"
          onChange={(e) =>
            setUserObject({ ...userObject, password: e.target.value })
          }
          placeholder="Password"
          autoComplete="on"
          required
        />
        <input
          type="password"
          onChange={(e) =>
            setUserObject({ ...userObject, confirmPassword: e.target.value })
          }
          placeholder="Confirm Password"
          autoComplete="on"
          required
        />
        <label htmlFor="locations">Choose a location:</label>
        <select
          id="location"
          name="locations"
          onChange={(e) =>
            setUserObject({ ...userObject, location: e.target.value })
          }
        >
          <option value="Parsons">Parsons</option>
          <option value="Caney">Caney</option>
          <option value="Emporia">Emporia</option>
          <option value="Hutchinson">Hutchinson</option>
          <option value="Fayetteville">Fayetteville</option>
        </select>
        <div className="permissions">
          <div>
            <h3>Contact permissions</h3>
            <p>
              (This is important so you can be notified when the order week for
              your area is open.)
            </p>
            <input
              id="phonePermission"
              type="checkbox"
              checked={phonePermission}
              onChange={() => setPhonePermissions(!phonePermission)}
            />
            <label htmlFor="phonePermission">
              I'm OK with getting a text when it's time to order.
            </label>
          </div>
          <div>
            <input
              id="emailPermission"
              type="checkbox"
              checked={emailPermission}
              onChange={() => setEmailPermissions(!emailPermission)}
            />
            <label htmlFor="emailPermission">
              I'm OK with getting an email when it's time to order.
            </label>
          </div>
        </div>

        <button className="login-button">Sign Up</button>
        {error && <p className="signup-error">{error}</p>}
        <div className="permissions">
          <p className="signup-note">
            Already have an account? {<Link to="/login">Login</Link>}{" "}
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signup;
