import ProductCard from "../Card";
import { useNavigate } from "react-router";
import { useEffect } from "react";

//import CSS
import "./Cart.css";

//components
import CheckoutFooter from "./CheckoutFooter";

//custom hooks
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCart } from "../../hooks/useCart";
import getAuthHeaders from "../../utils/getAuthHeaders";
import { useLocation } from "../../hooks/useLocation";
import { useUser } from "../../hooks/useUser";

const Cart = () => {
  const navigate = useNavigate();
  const { cart, cartTotal, updateCart } = useCart();
  const { user: userAuth } = useAuthContext();

  const HandleCheckout = () => {
    navigate("/cart/fulfillment");
  };

  const { location } = useLocation();
  const { user } = useUser();

  const ordersOpen = location ? location.ordersOpen : null;
  const signUpCity =
    user?.location && location?.signUpCities
      ? location.signUpCities.find((city) => city.name === user.location)
      : null;

  useEffect(() => {
    cart.forEach((item) => {
      if (item.quantityAvailable < 1) {
        alert(`Sorry! The product ${item.description} is now out of stock!`);
      }
    });
  }, []); // <-- empty dependency array means the function runs only once

  useEffect(() => {
    const syncCart = async () => {
      try {
        if (cart.length > 0) {
          const productIdArray = cart.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          }));
          const authHeader = await getAuthHeaders(userAuth);
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...authHeader,
            },
            body: JSON.stringify({ productObjects: productIdArray }),
          };

          // Update cart as soon as the page loads
          const fetchUrl =
            process.env.NODE_ENV === "development"
              ? `${process.env.REACT_APP_NETWORK}:4000/api/user/cart/${userAuth.uid}`
              : `/api/user/cart/${userAuth.uid}`;
          fetch(fetchUrl, options).then(async (r) => {
            const cartObject = await r.json();
            updateCart("SYNC_CART", { ...cartObject });
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    syncCart();
  }, [userAuth]);

  const CartNote = () => {
    return (
      <ul>
        <li>
          <strong> • Order Schedule:</strong> You can browse our products
          anytime, but orders can only be placed during designated order windows
          for your area.
        </li>
        <li>
          <strong> • Upcoming Order Window:</strong> Our next order window for{" "}
          {signUpCity.value} is {signUpCity.nextOrderWeek}. We'll send you a
          reminder when the order window opens if you've subscribed to our order
          reminders.
        </li>
        <li>
          <strong> • Important Inventory Note:</strong> Our inventory changes
          frequently, so some items may become unavailable by your order week.
          We're also adding new products daily, so you'll have fresh options
          when it's time to place your order!
        </li>
      </ul>
    );
  };

  return (
    <div>
      {<h1>Shopping Cart</h1>}
      {/*orders are closed*/}
      {!ordersOpen && signUpCity && (
        <div
          style={{
            padding: "50px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            fontFamily: "sans-serif",
          }}
        >
          <h3>
            Hi, {user.firstName}! Your next order week is{" "}
            {signUpCity.nextOrderWeek} for {signUpCity.value} pick-ups.
          </h3>
          <CartNote />
        </div>
      )}
      {/*orders are open, but not for this location*/}
      {ordersOpen && signUpCity && !signUpCity.ordersOpen && (
        <div
          style={{
            padding: "50px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            fontFamily: "sans-serif",
          }}
        >
          <h3>
            Hi, {user.firstName}! Your next order week is{" "}
            {signUpCity.nextOrderWeek} for {signUpCity.value} pick-ups. Orders
            are currently open for {location.orderCities} pick-ups.
          </h3>
          <CartNote />
        </div>
      )}
      {/*orders are open for this location*/}
      {ordersOpen && signUpCity && signUpCity.ordersOpen && (
        <div
          style={{
            padding: "50px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            fontFamily: "sans-serif",
          }}
        >
          <h3>
            Hi, {user.firstName}! It's order week for {location.orderCities}.
            Happy shopping!
          </h3>
          <ul>
            <li>
              <strong> • Order Deadline:</strong> Orders must be placed by the
              end of the day {location.orderDeadline}.
            </li>
            <li>
              <strong> • Important Inventory Note:</strong> Our inventory
              changes frequently, so some items may become unavailable. We're
              also adding new products daily so you'll have new options to
              choose from!
            </li>
          </ul>
        </div>
      )}
      {cart.length === 0 && <h3>Cart is Empty</h3>}
      <div className="product-grid shopping-cart">
        {cart.length > 0 &&
          cart.map((item) => {
            return <ProductCard key={item._id} item={item}></ProductCard>;
          })}
      </div>
      <CheckoutFooter
        cart={cart}
        cartTotal={cartTotal}
        clickAction={HandleCheckout}
        text="Checkout"
      />
    </div>
  );
};

export default Cart;
