import "./Navbar.css";
import fullIcon from "../images/White@svg-bold.svg";
import shortIcon from "../images/GDG.svg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNavicon,
  faMagnifyingGlass,
  faShoppingCart,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

//hooks
import { useLocation } from "../../hooks/useLocation";
import { useCart } from "../../hooks/useCart";

const Navbar = ({
  handleSearch,
  handleSidebar,
  handleOpenSearchModal,
  notification,
  handleNotification,
}) => {
  const { cartTotal, itemCount } = useCart();
  const { location } = useLocation();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
  };

  const navigate = useNavigate();

  const navigateToCart = () => {
    navigate("/cart");
  };

  return (
    <>
      <nav className="navbar grid">
        <div className="menuIcon">
          <FontAwesomeIcon
            icon={faNavicon}
            className="navicon"
            onClick={handleSidebar}
          />
        </div>
        <Link to="/" className="navbar-full-logo">
          <img src={fullIcon} alt="" />
        </Link>
        <Link to="/" className="navbar-short-logo">
          <img src={fullIcon} alt="" />
        </Link>
        <div className="searchBar">
          <form onSubmit={handleSubmit} className="searchbox">
            <input
              type="text"
              className="searchBox"
              placeholder="Search everything at Granddad's Groceries online and in store"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
            <button className="navButton" type="submit">
              Search
            </button>
          </form>
        </div>
        <div className="searchIcon">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="navicon"
            onClick={handleOpenSearchModal}
          />
        </div>
        <div className="cartIcon">
          <FontAwesomeIcon
            icon={faShoppingCart}
            className="navicon"
            onClick={navigateToCart}
          />
          {cartTotal > 0 && (
            <p className="cartAmount">
              ${Number.parseFloat(cartTotal / 100).toFixed(2)}
            </p>
          )}
          {itemCount > 0 && (
            <p
              className={
                itemCount > 9 ? "itemCount aboveTen" : "itemCount belowTen"
              }
            >
              {itemCount}
            </p>
          )}
        </div>
      </nav>
      {notification && !location.ordersOpen && (
        <div className="navbar-order-window grid">
          <Link to="/pickup-times" className="navbar-notification-link">
            <p className="navbar-order-text">
              Next Order Week: <b>{location.nextOrderWindow}</b>
              {" for pick-ups in "}
              <b>{location.orderCities}</b>
            </p>
          </Link>
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className="navbar-order-icon"
            onClick={handleNotification}
          />
        </div>
      )}
      {notification && location.ordersOpen && (
        <div className="navbar-order-window grid">
          <Link to="/order-schedule" className="navbar-notification-link">
            <p className="navbar-order-text">
              Orders now open for <b>{location.orderCities}</b> with{" "}
              <b>{location.nextDeliveryDate}</b> pick-up! Order Deadline:{" "}
              <b>{location.orderDeadline}</b>
            </p>
          </Link>
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className="navbar-order-icon"
            onClick={handleNotification}
          />
        </div>
      )}
      {notification && location.orderNotes && (
        <div className="navbar-order-window grid">
          <Link to="/pickup-times" className="navbar-notification-link">
            <p className="navbar-order-text">{location.orderNotes}</p>
          </Link>
        </div>
      )}
    </>
  );
};

export default Navbar;
