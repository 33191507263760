import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import homeCategoriesModule from "./homeCategories";

//hooks
import { useAuthContext } from "./hooks/useAuthContext";
import { useLocation } from "./hooks/useLocation";
import { useUser } from "./hooks/useUser";

//components
import Auth from "./components/Login/Auth";
import Login from "./components/Login/Login";
import Category from "./components/Navigation/Category";
import Products from "./components/Products";
import Navbar from "./components/Navigation/Navbar";
import Sidebar from "./components/Navigation/Sidebar";
import Signup from "./components/Login/Signup";
import SearchModal from "./components/Navigation/SearchModal";
import Cart from "./components/Checkout/Cart";
import Checkout from "./components/Checkout/Checkout";
import ImportantNote from "./components/Checkout/ImportantNote";
import Confirmation from "./components/Checkout/Confirmation";
import ProductDates from "./components/Pages/ProductDates";
import OrderInfo from "./components/Pages/OrderInfo";
import Learn from "./components/Pages/Learn";
import Tips from "./components/Pages/Tips";
import About from "./components/Pages/About";
import PickupTimes from "./components/Pages/PickupTimes";
import Contact from "./components/Pages/Contact";
import UserOrders from "./components/User/UserOrders";
import DigitalInvoice from "./components/User/DigitalInvoice";
import ResetPassword from "./components/Login/ResetPassword";
import AdminProducts from "./components/Admin/AdminProducts";
import getAuthHeaders from "./utils/getAuthHeaders";
import PickupOnly from "./components/Checkout/PickupOnly";

function App() {
  const [sidebar, setSidebar] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { user: userAuth, authIsReady } = useAuthContext();
  const [categories, setCategories] = useState([]);
  const [notification, setNotification] = useState(true);
  const [showLearn, setShowLearn] = useState(false);
  const homeCategories = homeCategoriesModule();
  const { location, setLocation } = useLocation();
  const { setUser } = useUser();

  useEffect(() => {
    const getCategories = async () => {
      if (userAuth) {
        try {
          const authHeader = await getAuthHeaders(userAuth);

          let categoriesUrl = "/api/products/categories";

          if (process.env.NODE_ENV === "development") {
            categoriesUrl = `${process.env.REACT_APP_NETWORK}:4000${categoriesUrl}`;
          }

          fetch(categoriesUrl, { headers: authHeader }).then(async (res) => {
            const json = await res.json();
            setCategories(json);
          });
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    getCategories();
  }, [userAuth]);

  useEffect(() => {
    if (userAuth) {
      const getLocation = async () => {
        try {
          const authHeader = await getAuthHeaders(userAuth);

          let locationUrl = `/api/regions/${userAuth.uid}`;

          if (process.env.NODE_ENV === "development") {
            locationUrl = `${process.env.REACT_APP_NETWORK}:4000${locationUrl}`;
          }

          fetch(locationUrl, { headers: authHeader }).then(async (res) => {
            const json = await res.json();
            setLocation(json);
          });
        } catch (err) {
          console.log(err.message);
        }
      };

      getLocation();
    }
  }, [userAuth, setLocation]);

  useEffect(() => {
    if (userAuth) {
      const getUser = async () => {
        try {
          const authHeader = await getAuthHeaders(userAuth);

          let userUrl = `/api/user/${userAuth.uid}`;

          if (process.env.NODE_ENV === "development") {
            userUrl = `${process.env.REACT_APP_NETWORK}:4000${userUrl}`;
          }

          fetch(userUrl, { headers: authHeader }).then(async (res) => {
            const json = await res.json();
            setUser(json);
          });
        } catch (err) {
          console.log(err.message);
        }
      };

      getUser();
    }
  }, [userAuth, setLocation]);

  const admin = ["b.j.newton@live.com", "c.m.newton@live.com"];

  const handleOpenSearchModal = () => {
    setShowSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setShowSearchModal(false);
  };

  const handleNotification = () => {
    setNotification(!notification);
  };

  const openNotification = useCallback(() => {
    setNotification(true);
  }, []);

  const handleSidebar = () => {
    setShowSearchModal(false);
    setSidebar(!sidebar);
  };

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          {userAuth && !showLearn && location && (
            <Navbar
              handleSidebar={handleSidebar}
              handleOpenSearchModal={handleOpenSearchModal}
              handleNotification={handleNotification}
              notification={notification}
            />
          )}
          {sidebar && (
            <Sidebar
              handleSidebar={handleSidebar}
              categories={categories}
              admin={admin}
            />
          )}
          {showSearchModal && (
            <SearchModal
              handleCloseSearchModal={handleCloseSearchModal}
              handleSidebar={handleSidebar}
            />
          )}
          <Routes>
            <Route
              path="/"
              element={
                userAuth ? (
                  <Category
                    homePage={true}
                    homeCategories={homeCategories}
                    handleSidebar={handleSidebar}
                  />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/products/"
              element={
                userAuth ? (
                  <Products categories={categories} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/login"
              element={
                userAuth ? <Navigate to="/" /> : <Auth form={<Login />} />
              }
            />
            <Route
              path="/login/reset-password"
              element={
                userAuth ? (
                  <Navigate to="/" />
                ) : (
                  <Auth form={<ResetPassword />} />
                )
              }
            />
            <Route
              path="/signup"
              element={
                userAuth ? (
                  <Navigate to="/learn" />
                ) : (
                  <Auth form={<Signup setShowLearn={setShowLearn} />} />
                )
              }
            />
            <Route
              path="/search"
              element={
                userAuth ? (
                  <SearchModal
                    handleCloseSearchModal={handleCloseSearchModal}
                    handleSidebar={handleSidebar}
                  />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/product-dates"
              element={userAuth ? <ProductDates /> : <Navigate to="/login" />}
            />
            <Route
              path="/order-schedule"
              element={
                userAuth ? (
                  <OrderInfo openNotification={openNotification} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/cart"
              element={userAuth ? <Cart /> : <Navigate to="/login" />}
            />
            <Route
              path="/cart/fulfillment"
              element={userAuth ? <PickupOnly /> : <Navigate to="/login" />}
            />
            <Route
              path="/cart/checkout"
              element={userAuth ? <Checkout /> : <Navigate to="/login" />}
            />
            <Route
              path="/cart/important-note"
              element={userAuth ? <ImportantNote /> : <Navigate to="/login" />}
            />
            <Route
              path="/cart/confirmation"
              element={userAuth ? <Confirmation /> : <Navigate to="/login" />}
            />
            <Route
              path="/learn"
              element={
                userAuth ? (
                  <Learn showLearn={showLearn} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/tips"
              element={
                userAuth ? (
                  <Tips setShowLearn={setShowLearn} showLearn={showLearn} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/about"
              element={
                userAuth ? (
                  <About setShowLearn={setShowLearn} showLearn={showLearn} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/pickup-times"
              element={
                userAuth ? (
                  <PickupTimes
                    setShowLearn={setShowLearn}
                    showLearn={showLearn}
                  />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/contact"
              element={userAuth ? <Contact /> : <Navigate to="/login" />}
            />
            <Route
              path="/orders"
              element={userAuth ? <UserOrders /> : <Navigate to="/login" />}
            />
            <Route
              path="/orders/:id"
              element={userAuth ? <DigitalInvoice /> : <Navigate to="/login" />}
            />
            <Route
              path="/*"
              element={
                userAuth ? <Navigate to="/" /> : <Navigate to="/login" />
              }
            />
            {userAuth && admin.includes(userAuth.email) && (
              <Route
                path="/admin/"
                element={
                  userAuth ? (
                    <AdminProducts categories={categories} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            )}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
