//style
import "./CheckoutFooter.css";

//custom hooks
import { useLocation } from "../../hooks/useLocation";
import { useUser } from "../../hooks/useUser";

export default function CheckoutFooter({
  cart,
  cartTotal,
  clickAction,
  text,
  disabled,
}) {
  const { location } = useLocation();
  const { user } = useUser();

  const ordersOpen = location ? location.ordersOpen : null;
  const signUpCity =
    user?.location && location?.signUpCities
      ? location.signUpCities.find((city) => city.name === user.location)
      : null;

  return (
    <>
      <div className="whiteSpace" />
      <div className="checkOut">
        {cart.length > 0 && (
          <p className="totalText">
            Cart Total: ${Number.parseFloat(cartTotal / 100).toFixed(2)}
          </p>
        )}
        {!ordersOpen && signUpCity && (
          <div className="window-notification">
            Our next order window for {signUpCity.value} is{" "}
            {signUpCity.nextOrderWeek}. We'll send you a reminder when the order
            window opens if you've subscribed to our order reminders.
          </div>
        )}
        {cart.length > 0 && (
          <button
            className={
              ordersOpen && !disabled ? "checkoutButton" : "disabledButton hide"
            }
            onClick={clickAction}
          >
            {text}
          </button>
        )}
      </div>
    </>
  );
}
