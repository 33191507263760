import "./Category.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Category({ homeCategories }) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="categories">
      <h1>Welcome to Granddad's Discount Groceries</h1>
      <h3>Browse Products by Category</h3>
      <div className="categories-grid">
        {homeCategories.map((category) => {
          return (
            <Link
              to={category.url}
              className="category-flex"
              key={category.url}
            >
              <div className="category-item">
                <img
                  className="category-image"
                  src={category.image}
                  alt={category.image}
                />
                <p className="category-title">{category.name}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
