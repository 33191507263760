import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { CartProvider } from "./context/CartContext";
import { AuthContextProvider } from "./context/AuthContext";
import { LocationContextProvider } from "./context/LocationContext";
import { UserContextProvider } from "./context/UserContext";
import * as Sentry from "@sentry/react";
import App from "./App";

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: "https://6ebe0d2a1e274f6bb7e244a363d39441@o4504049830526976.ingest.sentry.io/4505429023195136",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CartProvider>
      <UserContextProvider>
        <LocationContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </LocationContextProvider>
      </UserContextProvider>
    </CartProvider>
  </React.StrictMode>
);
