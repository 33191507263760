import { useState, useEffect, useCallback } from "react";
import { useCart } from "../hooks/useCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSnowflake,
  faTemperatureArrowDown,
  faJar,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import newItem from "../components/images/new-item.png";
import FsLightbox from "fslightbox-react";

//styles
import "./Products.css";

const Card = ({ item }) => {
  const { cart, updateCart } = useCart();
  const [quanity, setQuantity] = useState(null);
  const [showTemp, setShowTemp] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const itemQuantity = useCallback(
    (z) => {
      if (cart) {
        setQuantity(() => {
          const exist = cart.find((x) => x.productId === z.productId);
          if (exist) {
            return exist.quantity;
          } else {
            return null;
          }
        });
      }
    },
    [cart]
  );

  const handleShowTemp = () => {
    showTemp ? setShowTemp(false) : setShowTemp(true);
  };

  useEffect(() => {
    itemQuantity(item);
  }, [item, cart, itemQuantity]);

  const handleShowImage = () => {
    setShowImage(!showImage);
  };

  const handleImageType = (image) => {
    return typeof image === "string" ? [image] : image;
  };

  const handleCopy = (text) => {
    // Copy text to clipboard
    navigator.clipboard.writeText(text);

    // Show the alert for 5 seconds
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000); // 5000 milliseconds = 5 seconds
  };

  return (
    <div className="cell">
      <FsLightbox
        toggler={showImage}
        sources={handleImageType(item.image)}
        type="image"
      />
      <div className="item-grid">
        <div className="grid-child-1">
          {!item.numberOfCustomers &&
            new Date(item.dateAdded).getTime() >
              new Date().getTime() - 259200 * 1000 && (
              <img src={newItem} alt="" className="newItem" />
            )}
          <img
            src={item.thumbnail}
            className="item-photo"
            alt={item.description}
            onClick={handleShowImage}
          />
          <FontAwesomeIcon
            icon={faMagnifyingGlassPlus}
            className="faMagnifyingGlassPlus"
          />
        </div>
        <div className="grid-child-2">
          <div className="price">
            <p className="product-price">{`${item.unitCount} ${
              item.unitLabel === "lb." ? "lb." : ""
            } for $${Number.parseFloat(item.casePrice / 100).toFixed(2)}`}</p>
            <p className="price-per-unit">
              (${Number.parseFloat(item.unitPrice / 100).toFixed(2)} /{" "}
              {item.unitLabel === "lb." ? "lb." : "item"}
              {item.pricePerUnit &&
                item.unitLabel !== "lb." &&
                ` | ${item.pricePerUnit}`}
              )
            </p>
          </div>
          <p className={showAlert ? "text-copied" : "hide"}>Text copied!</p>
          <p
            className="product-description"
            onClick={() => handleCopy(`${item.description} ${item.unitSize}`)}
          >
            {item.description}, {item.unitSize}
          </p>
          <div className="cell-container">
            {item.date.slice(0, 2) === "BB" && (
              <p className="show">
                <b>BB</b> - Best By Date
              </p>
            )}
            {item.date.slice(0, 2) === "UB" && (
              <p className="show">
                <b>UB</b> - Use By Date
              </p>
            )}
            {item.date.slice(0, 2) === "EX" && (
              <p className="show">
                <b>EX</b> - Expiration Date
              </p>
            )}
            {item.date.slice(0, 2) === "FB" && (
              <p className="show">
                <b>FB</b> - Freeze By Date
              </p>
            )}
            {item.date.slice(0, 2) === "PD" && (
              <p className="show">
                <b>PD</b> - Production Date
              </p>
            )}
            {item.date.slice(0, 2) === "SB" && (
              <p className="show">
                <b>SB</b> - Sell By Date
              </p>
            )}
            <p className="cell-item product-date">{item.date}</p>
            <p className="cell-item">
              Cases Available: <b>{item.quantityAvailable}</b>
            </p>
            {item.quantityAvailable === 0 && <p>Out of Stock</p>}
          </div>
          {item.casePrice && (
            <div className="addButton">
              {!quanity && item.quantityAvailable !== 0 && (
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    updateCart("ADD_ITEM", item);
                  }}
                >
                  + Add
                </button>
              )}
              {item.quantityAvailable === 0 && item.numberOfCustomers && (
                <h3>Sold out :)</h3>
              )}
              {item.quantityAvailable === 0 && !item.numberOfCustomers && (
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    updateCart("REMOVE_ITEM", item);
                  }}
                >
                  Remove
                </button>
              )}
              {quanity && item.quantityAvailable > 0 && (
                <div className="addition">
                  <button
                    onClick={() => {
                      updateCart("REMOVE_ITEM", item);
                    }}
                  >
                    -
                  </button>
                  <span> {quanity > 0 && quanity} </span>
                  <button
                    onClick={() => {
                      updateCart("ADD_ITEM", item);
                    }}
                  >
                    +
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="white-circle">
            {item.temperature[0] === "F" && !showTemp && (
              <FontAwesomeIcon
                icon={faSnowflake}
                className="temperature-icon"
                onClick={() => handleShowTemp()}
              />
            )}
            {item.temperature[0] === "F" && showTemp && (
              <p className="icon-description" onClick={() => handleShowTemp()}>
                Frozen
              </p>
            )}
            {item.temperature[0] === "R" && !showTemp && (
              <FontAwesomeIcon
                icon={faTemperatureArrowDown}
                className="temperature-icon"
                onClick={() => handleShowTemp()}
              />
            )}
            {item.temperature[0] === "R" && showTemp && (
              <p className="icon-description" onClick={() => handleShowTemp()}>
                Refrigerated
              </p>
            )}
            {item.temperature[0] === "D" && !showTemp && (
              <FontAwesomeIcon
                icon={faJar}
                className="temperature-icon"
                onClick={() => handleShowTemp()}
              />
            )}
            {item.temperature[0] === "D" && showTemp && (
              <p className="icon-description" onClick={() => handleShowTemp()}>
                Non-Perishable
              </p>
            )}
          </div>
        </div>
      </div>
      <p className="cell-product-id">
        <b>#{item.productId}</b>
      </p>
      {item.numberOfCustomers && item.numberOfCustomers >= 1 && (
        <p className="popular-item">
          {item.numberOfCustomers}{" "}
          {item.numberOfCustomers > 1 ? "cases" : "case"} purchased this month!
        </p>
      )}
      {item.numberOfCustomers && item.numberOfCustomers >= 1 && (
        <p className="popular-item-number">{item.numberOfCustomers}</p>
      )}
    </div>
  );
};

export default Card;
