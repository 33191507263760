import "./Sidebar.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useUser } from "../../hooks/useUser";
import { useLogout } from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faCaretDown,
  faCaretUp,
  faLightbulb,
  faEnvelope,
  faUserGear,
  faFileInvoice,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";

export default function Sidebar({ handleSidebar, categories, admin }) {
  const { user: userAuth } = useAuthContext();
  const { user } = useUser();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");

  const customLogout = () => {
    logout();
    handleSidebar();
    navigate("/");
  };

  const handleNavigate = (url) => {
    handleSidebar();
    navigate(url);
  };

  const handleSelectCategory = (category) => {
    if (category === selectedCategory) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(category);
    }
  };

  return (
    <div className="modal-flex grid">
      <div className="modal-nav">
        <div className="navigation-items">
          {user && (
            <div>
              <p>Hi, {user.firstName}!</p>
            </div>
          )}
          {userAuth && (
            <div
              className="navigation-flex"
              onClick={customLogout}
              key="Logout"
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className="navpop-icon"
              />
              <p>Logout</p>
            </div>
          )}
          <div
            className="navigation-flex"
            onClick={() => handleNavigate("/orders")}
            key="Previous Orders"
          >
            <FontAwesomeIcon icon={faFileInvoice} className="navpop-icon" />
            <p>Previous Orders</p>
          </div>
          <div
            className="navigation-flex"
            onClick={() => handleNavigate("/tips")}
            key="Product Card Guide"
          >
            <FontAwesomeIcon icon={faLightbulb} className="navpop-icon" />
            <p>Product Card Guide</p>
          </div>
          <div
            className="navigation-flex"
            onClick={() => handleNavigate("/about")}
            key="About Us"
          >
            <FontAwesomeIcon icon={faSmile} className="navpop-icon" />
            <p>About Us</p>
          </div>
          {userAuth && admin.includes(userAuth.email) && (
            <div
              className="navigation-flex"
              onClick={() => handleNavigate("/admin")}
              key="Admin"
            >
              <FontAwesomeIcon icon={faUserGear} className="navpop-icon" />
              <p>Admin</p>
            </div>
          )}
        </div>
        <p className="navigation-header">
          <b>Shop</b>
        </p>
        <div className="navigation-items">
          {categories.map((cat) => {
            const categoryName = cat._id;
            if (categoryName.toUpperCase() === "IC CREAM") {
              return null;
            }
            return (
              <div key={categoryName}>
                <div className="navigation-item">
                  <p
                    className="navigation-description"
                    onClick={() =>
                      handleNavigate(
                        `/products/?category=${encodeURIComponent(
                          categoryName
                        )}`
                      )
                    }
                  >
                    {categoryName.charAt(0) +
                      categoryName.slice(1).toLowerCase()}
                  </p>
                  <FontAwesomeIcon
                    icon={
                      selectedCategory === categoryName
                        ? faCaretUp
                        : faCaretDown
                    }
                    className="navpop-icon"
                    onClick={() => handleSelectCategory(categoryName)}
                  />
                </div>
                {cat.subCategory.map((sc) => {
                  return (
                    <p
                      key={sc}
                      className={`subcategory ${
                        selectedCategory === categoryName ? "" : "hide"
                      }`}
                      onClick={() =>
                        handleNavigate(
                          `/products/?category=${encodeURIComponent(
                            categoryName
                          )}&subCategory=${encodeURIComponent(sc)}`
                        )
                      }
                    >
                      {sc}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
        <p className="navigation-header">
          <b>Help</b>
        </p>
        <div className="navigation-items">
          <div
            className="navigation-flex"
            onClick={() => handleNavigate("/contact")}
            key="Contact Us"
          >
            <FontAwesomeIcon icon={faEnvelope} className="navpop-icon" />
            <p>Contact Us</p>
          </div>
          <div
            className="navigation-flex"
            onClick={() => handleNavigate("/learn")}
            key="Order Schedule"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="navpop-icon" />
            <p>Learn More</p>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={handleSidebar}>
        .
      </div>
    </div>
  );
}
