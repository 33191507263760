import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSnowflake,
  faTemperatureArrowDown,
  faJar,
} from "@fortawesome/free-solid-svg-icons";
import FsLightbox from "fslightbox-react";

//styles
import "../Products.css";
import "./AdminCard.css";

const AdminCard = ({ item, handleUpdatedProducts }) => {
  const [markup, setMarkup] = useState(70);
  const [casePrice, setCasePrice] = useState(0);
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [add, setAdd] = useState(false);
  const [reject, setReject] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (item) {
      handleCasePrice(item.casePrice);
      //handlePricePerUnit(item.casePrice, item.unitSize);
    }
  }, []); // <-- empty dependency array means the function runs only once

  useEffect(() => {
    if (item) {
      handlePricePerUnit(casePrice, item.unitSize);
    }
  }, [markup, casePrice]);

  const handleMarkup = (markup, unitSize) => {
    setMarkup(markup);

    const updatedCasePrice = Math.ceil(
      (markup * item.wholesalePrice) / 100 + item.wholesalePrice
    );

    setCasePrice(updatedCasePrice);
  };

  const handleCasePrice = (casePrice, unitSize) => {
    //setCasePrice(Math.ceil(casePrice / 100) * 100);
    setCasePrice(Number(casePrice));

    const updatedMarkup = Math.round(
      (casePrice / item.wholesalePrice - 1) * 100
    );

    setMarkup(updatedMarkup);
  };

  const handlePricePerUnit = (casePrice, unitSize) => {
    const updatedPricePerUnit = calculatePricePerUnit(casePrice, unitSize);
    setPricePerUnit(updatedPricePerUnit);
  };

  function calculatePricePerUnit(itemCasePrice, itemUnitSize) {
    if (!itemCasePrice || !itemUnitSize) {
      return null;
    }

    const price = itemCasePrice;
    const unitsAndOunces = itemUnitSize;

    const matches = unitsAndOunces.match(/x/g);
    if (!matches) return null;

    // Regular expressions to extract units and ounces
    const unitsRegex = /^(\d+)/;
    const doubleUnitRegex = /(?<=(x))(\d+)?.{0,1}(\d+)\s*(?=(x))/;
    const ouncesRegex = /(?!x)(\d+)?.{0,1}(\d+)\s*(?=(oz|#|Gal))/;
    const typeRegex = /(oz|#|Gal)/;

    // Extract units and ounces using regex
    const unitsMatch = unitsRegex.exec(unitsAndOunces);
    const doubleUnitMatch = doubleUnitRegex.exec(unitsAndOunces);
    const ouncesMatch = ouncesRegex.exec(unitsAndOunces);
    const typeMatch = typeRegex.exec(unitsAndOunces);

    if (!unitsMatch || !ouncesMatch || !typeMatch) return null;

    if (matches.length > 1 && doubleUnitMatch === null) return null;

    const type = typeMatch[0];
    let unitType;
    const isGallon = type === "Gal";
    const isPound = type === "#";
    const unitCount = isGallon ? 128 : parseFloat(unitsMatch[0]);
    const doubleUnit =
      matches.length > 1 && doubleUnitMatch !== null
        ? parseFloat(doubleUnitMatch[0])
        : 1;
    const unitSize = parseFloat(ouncesMatch[0]);

    if (isGallon) {
      unitType = "fl oz.";
    } else if (isPound) {
      unitType = "lb";
    } else {
      unitType = type;
    }

    const totalOunces = unitCount * doubleUnit * unitSize;

    const pricePerUnit = price / totalOunces / 100;

    const isLessThanOne = pricePerUnit < 1;

    if (unitType === "oz" && isLessThanOne) {
      return `${Number(pricePerUnit * 100).toFixed(2)}¢/${unitType}`;
    } else {
      return `$${Number(pricePerUnit).toFixed(2)}/${unitType}`;
    }
  }

  const handleUpdatedItem = (oldItem, action) => {
    const newUnitPrice = Math.round(casePrice / oldItem.unitCount);
    const updatedItem = oldItem;

    updatedItem.markup = markup;
    updatedItem.casePrice = casePrice;
    updatedItem.unitPrice = newUnitPrice;
    updatedItem.pricePerUnit = pricePerUnit;
    updatedItem.inventoryStatus = action;

    return updatedItem;
  };

  const handleReject = (oldItem) => {
    if (reject) {
      setReject(false);
      handleUpdatedProducts(oldItem, "REMOVE_ITEM");
    } else {
      setAdd(false);
      setReject(true);

      const updatedItem = handleUpdatedItem(oldItem, "exclude");
      handleUpdatedProducts(updatedItem, "ADD_ITEM");
    }
  };

  const handleAdd = (oldItem) => {
    if (add) {
      setAdd(false);
      handleUpdatedProducts(oldItem, "REMOVE_ITEM");
    } else {
      setReject(false);
      setAdd(true);
      const updatedItem = handleUpdatedItem(oldItem, "include");
      handleUpdatedProducts(updatedItem, "ADD_ITEM");
    }
  };

  const handleBorder = () => {
    if (add) {
      return "cell admin-card-add";
    } else if (reject) {
      return "cell admin-card-reject";
    } else {
      return "cell";
    }
  };

  const handleShowImage = () => {
    setShowImage(!showImage);
  };

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleImageType = (image) => {
    return typeof image === "string" ? [image] : image;
  };

  const handleCopy = (text) => {
    // Copy text to clipboard
    navigator.clipboard.writeText(text);

    // Show the alert for 5 seconds
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000); // 5000 milliseconds = 5 seconds
  };

  const getCaseRevenue = () => {
    return Number(
      ((markup * item.wholesalePrice) / 100 + item.wholesalePrice) / 100 -
        item.wholesalePrice / 100
    ).toFixed(2);
  };

  return (
    <div className={`${handleBorder()} admin-card`}>
      <FsLightbox
        toggler={showImage}
        sources={handleImageType(item.image)}
        type="image"
      />
      <div className="item-grid">
        <div className="grid-child-1">
          <p className="wholesale-price">
            ${Number(item.wholesalePrice / 100).toFixed(2)}
            {item.updateLog && (
              <i>
                {" "}
                (was $
                {Number(item.updateLog[0].wholesalePrice / 100).toFixed(2)})
              </i>
            )}
          </p>
          <img
            src={item.thumbnail}
            className="item-photo"
            alt={item.description}
            onClick={handleShowImage}
          />
        </div>
        <div className="grid-child-2">
          <div className="price">
            <p className="product-price">{`${item.unitCount} ${
              item.unitLabel === "lb." ? "lb." : ""
            } for $${Number.parseFloat(casePrice / 100).toFixed(2)}`}</p>
            <p className="price-per-unit">
              (${Number.parseFloat(casePrice / item.unitCount / 100).toFixed(2)}{" "}
              / {item.unitLabel === "lb." ? "lb." : "item"}
              {pricePerUnit && ` | ${pricePerUnit}`})
            </p>
          </div>
          <p className={showAlert ? "text-copied" : "hide"}>Text copied!</p>
          <p
            className="product-description"
            onClick={() => handleCopy(`${item.description} ${item.unitSize}`)}
          >
            {item.description}, {item.unitSize}
          </p>
          <p style={{ paddingBottom: "10px" }}>
            <span className={getCaseRevenue() >= 5 ? "highlight" : ""}>
              ${getCaseRevenue()}
            </span>{" "}
            - Case Revenue
          </p>
          <div className="cell-container">
            {item.date.slice(0, 2) === "BB" && (
              <p className="show">
                <b>BB</b> - Best By Date
              </p>
            )}
            {item.date.slice(0, 2) === "UB" && (
              <p className="show">
                <b>UB</b> - Use By Date
              </p>
            )}
            {item.date.slice(0, 2) === "EX" && (
              <p className="show">
                <b>EX</b> - Expiration Date
              </p>
            )}
            {item.date.slice(0, 2) === "FB" && (
              <p className="show">
                <b>FB</b> - Freeze By Date
              </p>
            )}
            {item.date.slice(0, 2) === "PD" && (
              <p className="show">
                <b>PD</b> - Production Date
              </p>
            )}
            {item.date.slice(0, 2) === "SB" && (
              <p className="show">
                <b>SB</b> - Sell By Date
              </p>
            )}
            <p className="cell-item product-date">{item.date}</p>
            <p className="cell-item">
              Cases Available: <b>{item.quantityAvailable}</b>
            </p>
          </div>
          {item.upc && showDetails && (
            <p>UPC: {item.upc.replaceAll("-", "")}</p>
          )}
          {item.multipack && showDetails && (
            <p>Multipack: {item.multipack.replaceAll("-", "")}</p>
          )}
          {item.productId && showDetails && <p>Product #: {item.productId}</p>}
          {item.inventoryStatus && (
            <p>
              Type: <b>{item.inventoryStatus}</b>
            </p>
          )}
          {item.updateLog && (
            <p>Previous casePrice: {item.updateLog[0].casePrice}</p>
          )}
          {item.updateLog && (
            <p>Previous status: {item.updateLog[0].inventoryStatus}</p>
          )}
          <input
            className={`admin-card-markup ${markup >= 50 ? "highlight" : ""}`}
            type="number"
            onChange={(e) => handleMarkup(e.target.value)}
            value={markup}
            disabled={add || reject}
            step="5"
          />
          <input
            className="admin-card-markup"
            type="number"
            onChange={(e) => handleCasePrice(e.target.value)}
            value={casePrice}
            disabled={add || reject}
            step="100"
          />
          <div className="admin-card-button-flex">
            <input
              type="checkbox"
              className="admin-add-item"
              checked={add}
              disabled={reject}
              onChange={() => {
                handleAdd(item);
              }}
            />
            <button
              type="button"
              className="button admin-card-remove"
              onClick={() => {
                handleReject(item);
              }}
            >
              Reject
            </button>
          </div>
          <div className="white-circle" onClick={handleShowDetails}>
            {item.temperature[0] === "F" && (
              <FontAwesomeIcon
                icon={faSnowflake}
                className="temperature-icon"
              />
            )}
            {item.temperature[0] === "F" && (
              <p className="icon-description show">Frozen</p>
            )}
            {item.temperature[0] === "R" && (
              <FontAwesomeIcon
                icon={faTemperatureArrowDown}
                className="temperature-icon"
              />
            )}
            {item.temperature[0] === "R" && (
              <p className="icon-description show">Refrigerated</p>
            )}
            {item.temperature[0] === "D" && (
              <FontAwesomeIcon icon={faJar} className="temperature-icon" />
            )}
            {item.temperature[0] === "D" && (
              <p className="icon-description show">Non-Perishable</p>
            )}
          </div>
        </div>
      </div>
      <p className="cell-product-id">
        <b>#{item.productId}</b>
      </p>
    </div>
  );
};

export default AdminCard;
