import "../Products.css";
import AdminCard from "./AdminCard";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import Filter from "../Navigation/Filter";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import getAuthHeaders from "../../utils/getAuthHeaders";
import { useAuthContext } from "../../hooks/useAuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

const AdminProducts = ({ categories }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [updatedProducts, setUpdatedProducts] = useState([]);

  const category = searchParams.get("category") || null;
  const query = searchParams.get("q") || null;
  const pageNum = searchParams.get("page") || 1;
  const subCategory = searchParams.get("subCategory") || null;
  const [url, setUrl] = useState("/api/admin/");
  const displayAll = searchParams.get("display") || false;
  const temp = searchParams.get("temp") || null;

  const { data, isPending, error } = useFetch(url);

  let items = null;
  let resultsCount = null;

  if (data) {
    if (data.docs) {
      items = data.docs;
    }

    if (data.meta) {
      if (data.meta.length > 0) {
        resultsCount = data.meta[0].total;
      }
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const generateUrl = () => {
      let newUrl = "/api/admin?";
      let parameters = "";
      let paramArray = [];

      if (query) {
        paramArray.push(`&q=${encodeURIComponent(query)}`);
      }
      if (category) {
        paramArray.push(`&category=${encodeURIComponent(category)}`);
      }
      if (pageNum) {
        paramArray.push(`&page=${pageNum}`);
      }
      if (subCategory && subCategory !== "All") {
        paramArray.push(`&subCategory=${encodeURIComponent(subCategory)}`);
      }
      if (displayAll) {
        paramArray.push(`&display=ALL`);
      }

      paramArray.forEach((param) => {
        parameters = parameters + param;
      });

      setUrl(newUrl + parameters);
    };

    generateUrl();
  }, [query, pageNum, category, subCategory, displayAll]);

  const handleNewFilter = (type, filter) => {
    let parameters = {};

    if (category) {
      parameters.category = category;
    }
    if (subCategory) {
      parameters.subCategory = subCategory;
    }
    if (temp) {
      parameters.temp = temp;
    }

    if (filter) {
      if (type === "subCat") {
        parameters.subCategory = filter;
      }
      if (type === "temp") {
        parameters.temp = filter;
      }
    }

    setSearchParams(parameters);
  };

  const handleFinalPageNumber = () => {
    if (Number(pageNum) * 36 - resultsCount < 36) {
      return Number(pageNum) * 36 < resultsCount
        ? Number(pageNum) * 36
        : resultsCount;
    } else {
      return Number(pageNum) * 36;
    }
  };

  const handleUpdatedProducts = (item, action) => {
    const prevCart = updatedProducts;
    let cart = [];
    let exist = item
      ? prevCart.find((x) => x.productId === item.productId)
      : false;

    switch (action) {
      case "ADD_ITEM":
        if (!exist) {
          cart = [...prevCart, { ...item, quantity: 1 }];
        }
        break;
      case "REMOVE_ITEM":
        if (exist) {
          cart = prevCart.filter((x) => x.productId !== item.productId);
        }
        break;
      default:
        throw new Error();
    }

    setUpdatedProducts(cart);
  };

  const handleSubmit = async () => {
    try {
      const fetchUrl =
        process.env.NODE_ENV === "development"
          ? `${process.env.REACT_APP_NETWORK}:4000/api/admin/products/`
          : `/api/admin/products/`;

      const authHeader = await getAuthHeaders(user);

      const response = await fetch(fetchUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify({ products: updatedProducts }),
      });

      const data = await response.json();

      let modifiedCount = 0;
      data.forEach((item) => {
        if (item.modifiedCount === 1) {
          modifiedCount++;
        }
      });

      alert(`${data.length} items submitted. ${modifiedCount} items updated.`);

      window.location.reload(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCategory = (cat) => {
    navigate(`/admin?category=${encodeURIComponent(cat)}`);
  };

  const handleNextPage = (upOrDown) => {
    const parameters = {};

    if (query) {
      parameters.q = query;
    }
    if (category) {
      parameters.category = category;
    }
    if (subCategory) {
      parameters.subCategory = subCategory;
    }
    if (temp) {
      parameters.temp = temp;
    }
    if (pageNum) {
      parameters.page = Number(pageNum) + upOrDown;
    } else {
      parameters.page = 2;
    }
    setSearchParams(parameters);
  };

  return (
    <div className="home">
      <a href="/admin">
        <h1>Admin Pricing Page</h1>
      </a>
      {categories &&
        categories.map((cat) => {
          const categoryName = cat._id;
          console.log();
          if (categoryName.toUpperCase() === "ICE CREAM") {
            return null;
          }
          return (
            <button
              key={categoryName}
              onClick={() => handleCategory(categoryName)}
            >
              {categoryName}
            </button>
          );
        })}
      {category && <h1>{category.toUpperCase()}</h1>}
      {isPending && <Loading />}
      {items && category && (
        <Filter
          categories={categories}
          currentCategory={category}
          subCategory={subCategory}
          handleNewFilter={handleNewFilter}
          temp={temp}
          category={category}
        />
      )}
      {error && <div>{error}</div>}
      <div className="product-grid">
        {items &&
          items.map((item) => {
            return (
              <AdminCard
                key={item._id}
                item={item}
                handleUpdatedProducts={handleUpdatedProducts}
              ></AdminCard>
            );
          })}
      </div>
      <div className="upper-pageNavigation">
        {resultsCount &&
          `Showing items ${
            Number(pageNum) > 1 ? (Number(pageNum) - 1) * 36 + 1 : 1
          }-${handleFinalPageNumber()} of ${resultsCount}`}
        <div className="pageNavigation">
          {Number(pageNum) > 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="chevron"
              onClick={() => handleNextPage(-1)}
            />
          )}
          {Number(pageNum) === 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="disabled-chevron"
            />
          )}
          <p>{pageNum}</p>
          {Number(pageNum) * 36 < resultsCount && (
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="chevron"
              onClick={() => handleNextPage(1)}
            />
          )}
          {!(Number(pageNum) * 36 < resultsCount) && (
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="disabled-chevron"
            />
          )}
          <button
            type="button"
            className="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
